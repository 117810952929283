import React, { FC, useCallback, useEffect, useState } from "react";
import { Button } from "pyxis-ui-kit";
import { Modal, Input } from "antd";

interface CreateResourceModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalTitle: string;
  modalDesc: string;
  text: string;
  setText: (title: string) => void;
  handleCreateButton: Function;
}

const maxTextLength = 50;
const CreateResourceModal: FC<CreateResourceModalProps> = ({
  isModalOpen,
  setModalOpen,
  modalTitle = "",
  modalDesc = "",
  text,
  setText,
  handleCreateButton,
}) => {
  const [isInputTextLengthExceeds, setIsInputTextLengthExceeds] = useState<
    boolean
  >(false);
  useEffect(() => {
    if (!!isModalOpen) {
      setIsInputTextLengthExceeds(false);
    }
  }, [isModalOpen]);
  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value.length >= maxTextLength) {
        setIsInputTextLengthExceeds(true);
        return;
      } else {
        setText(e.target.value);
        setIsInputTextLengthExceeds(false);
      }
    },
    [setText]
  );

  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
    setText("");
  }, [setModalOpen, setText]);

  return (
    <Modal
      centered
      visible={isModalOpen}
      footer={null}
      className="add-new-agency-modal"
      onCancel={handleCancelButton}
    >
      <h2 className="modal-title">{modalTitle}</h2>
      <div className="modal-form">
        {modalDesc && <p>{modalDesc}</p>}
        <Input
          className="input-field"
          type="text"
          size="large"
          placeholder="Untitled"
          value={text}
          onChange={handleInputChange}
          onPressEnter={() => handleCreateButton(text)}
          autoFocus
          maxLength={maxTextLength}
        />
      </div>
      {!!isInputTextLengthExceeds ? (
        <span style={{ color: "red" }}>
          Maximum length for the title is {maxTextLength} characters.
        </span>
      ) : (
        <div />
      )}
      <div className="modal-buttons">
        <Button
          className="button create-button"
          type="primary"
          disabled={!text.trim().length}
          onClick={() => handleCreateButton(text)}
        >
          Create
        </Button>
        <Button className="button cancel-button" onClick={handleCancelButton}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CreateResourceModal;
