import {
  ADD_NEW_AGENCY,
  AddNewAgency,
  Agencies,
  AgencyList,
  CREATE_NEW_AGENCY,
  CreateNewAgency,
  DELETE_AGENCY,
  FETCH_AGENCY_LIST,
  DeleteAgency,
  FetchAgencyList,
  GET_AGENCY_LIST,
  GetAgencyList,
  FETCH_AGENCY_FOLDERS,
  FetchAgencyFolders,
  SelectedAgency,
  SetAgencyFolders,
  SET_AGENCY_FOLDERS,
  CREATE_NEW_FOLDER,
  CreateNewFolder,
  AgencyFolder,
  AddNewAgencyFolder,
  ADD_NEW_AGENCY_FOLDER,
  FetchAgencyData,
  FETCH_AGENCY_DATA,
  DELETE_AGENCY_FOLDER,
  DeleteAgencyFolder,
  RenameAgencyFolder,
  RENAME_AGENCY_FOLDER,
  SET_AGENCY_DATA,
  SetAgencyData,
  GET_AGENCY_SHAREABLE_KEY,
  GetAgencyShareableKey,
  SetAgencyShareableKey,
  SET_AGENCY_SHAREABLE_KEY,
  AgencyToBeShared,
  PostAgencyShareableKey,
  POST_AGENCY_SHAREABLE_KEY,
  GET_AGENCY_FILES_LIST,
  GetAgencyFilesList,
  UploadFileInAgencyAction,
  UPLOAD_FILE_IN_AGENCY,
  RESET_AGENCY_FILE_DROP,
  ResetAgencyFileDropAction,
  STOP_LOADING,
  StopLoadingOnErrorAction,
  SetActiveFolderId,
  SET_ACTIVE_FOLDER_ID,
  CurrentAgencyFolderData,
  SetCurrentAgencyFolderDataBreadcrumbsAction,
  SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS,
  CreateAgencyFolderStructure,
  CREATE_AGENCY_FOLDER_STRUCTURE,
  SET_UPLOAD_FILE_IN_AGENCY,
  SetUploadFileInAgencyAction,
  RESET_FILES_AND_FOLDERS_BREADCRUMBS,
  ResetFilesAndFoldersBreadcrumbsAction,
  KeepFilesAndFoldersBreadcrumbsAction,
  KEEP_FILES_AND_FOLDERS_BREADCRUMBS,
  DELETE_FOLDER_FILES,
  DeleteFolderFiles,
  REMOVE_DELETED_FOLDER_FROM_PARENT,
  RemoveDeletedFolderFromParent,
  RemoveDeletedFolderFromAgency,
  REMOVE_DELETED_FOLDER_FROM_AGENCY,
  FetchDAMFoldersAction,
  FETCH_DAM_FOLDERS,
  SetDAMFoldersAction,
  SET_DAM_FOLDERS,
  ResetDamFoldersBreadcrumbs,
  RESET_DAM_FOLDERS_BREADCRUMBS,
  REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX,
  RemoveFoldersFromBreadcrumbsWithIndex,
  ADD_NEW_FOLDER_FOR_COPY,
  AddNewFolderForCopy,
  RenameTheNewlyAddedFolderForCopy,
  RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY,
  ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS,
  AddNewFolderIntoDamBreadcrumbs,
  CopyFileAndFoldersToDamAction,
  COPY_FILES_AND_FOLDERS_TO_DAM_ACTION,
  SetFilesToBeUploaded,
  SET_FILES_TO_BE_UPLOADED,
  SetUploadingError,
  SET_UPLOADING_ERROR,
} from "../types";

export const resetAgencyFileDropAction = (): ResetAgencyFileDropAction => ({
  type: RESET_AGENCY_FILE_DROP,
});

export const stopLoadingOnErrorAction = (): StopLoadingOnErrorAction => ({
  type: STOP_LOADING,
});

export const resetFilesAndFoldersBreadcrumbsAction = (): ResetFilesAndFoldersBreadcrumbsAction => ({
  type: RESET_FILES_AND_FOLDERS_BREADCRUMBS,
});

export const resetDamFoldersBreadcrumbs = (): ResetDamFoldersBreadcrumbs => ({
  type: RESET_DAM_FOLDERS_BREADCRUMBS,
});

export const fetchAgencyListAction = (payload: {
  workspace: string;
  page?: number;
}): FetchAgencyList => ({
  type: FETCH_AGENCY_LIST,
  payload,
});

export const getAgencyList = (payload: {
  data: AgencyList;
  page: number;
  shouldLoading?: boolean;
}): GetAgencyList => ({
  type: GET_AGENCY_LIST,
  payload,
});

export const createNewAgency = (payload: {
  agency_name: string;
  workspace: string;
  parent?: string;
}): CreateNewAgency => ({
  type: CREATE_NEW_AGENCY,
  payload,
});

export const addNewAgency = (payload: Agencies): AddNewAgency => ({
  type: ADD_NEW_AGENCY,
  payload,
});

export const fetchAgencyData = (agency_id: string): FetchAgencyData => ({
  type: FETCH_AGENCY_DATA,
  payload: agency_id,
});

export const deleteAgency = (payload: {
  id: string;
  workspace: string;
}): DeleteAgency => ({
  type: DELETE_AGENCY,
  payload,
});

export const fetchAgencyFolders = (payload: {
  agency_id: string;
  page?: number;
  isAgencyChanged?: boolean;
  parent?: string;
  folderToBeRemoved?: string;
}): FetchAgencyFolders => ({
  type: FETCH_AGENCY_FOLDERS,
  payload,
});

export const setAgencyFolders = (payload: {
  data: SelectedAgency;
  page?: number;
  parent?: string;
  folderToBeRemoved?: string;
}): SetAgencyFolders => ({
  type: SET_AGENCY_FOLDERS,
  payload,
});

export const createNewFolder = (payload: {
  name: string;
  agency_id: string;
  parent?: string;
}): CreateNewFolder => ({
  type: CREATE_NEW_FOLDER,
  payload,
});

export const addNewAgencyFolder = (
  payload: AgencyFolder
): AddNewAgencyFolder => ({
  type: ADD_NEW_AGENCY_FOLDER,
  payload,
});

export const deleteAgencyFolder = (payload: {
  id: string;
  agencyId: string;
}): DeleteAgencyFolder => ({
  type: DELETE_AGENCY_FOLDER,
  payload,
});

export const renameAgencyFolder = (payload: {
  id: string;
  name: string;
}): RenameAgencyFolder => ({
  type: RENAME_AGENCY_FOLDER,
  payload,
});

export const setAgencyData = (payload: Agencies): SetAgencyData => ({
  type: SET_AGENCY_DATA,
  payload,
});

export const getAgencyShareableKeyAction = (payload: {
  password_flag: boolean;
  agency: string;
}): GetAgencyShareableKey => ({
  type: GET_AGENCY_SHAREABLE_KEY,
  payload,
});

export const postAgencyShareableKeyAction = (payload: {
  password_flag: boolean;
  agency: string;
}): PostAgencyShareableKey => ({
  type: POST_AGENCY_SHAREABLE_KEY,
  payload,
});

export const setAgencyShareableKey = (
  payload: AgencyToBeShared
): SetAgencyShareableKey => ({
  type: SET_AGENCY_SHAREABLE_KEY,
  payload,
});

export const getAgencyFilesList = (payload: {
  agency_id: string;
  folders: string;
  page?: number;
}): GetAgencyFilesList => ({
  type: GET_AGENCY_FILES_LIST,
  payload,
});

export const uploadFileInAgencyAction = (payload: {
  file: any;
  agency_id: string;
  folder: string;
  title: string;
  file_type: string;
  isFolder: boolean;
  folders: any;
  fileType: string;
}): UploadFileInAgencyAction => ({
  type: UPLOAD_FILE_IN_AGENCY,
  payload,
});

export const setActiveFolder = (payload: {
  folderId: string;
}): SetActiveFolderId => ({
  type: SET_ACTIVE_FOLDER_ID,
  payload,
});

export const setCurrentAgencyFolderDataBreadcrumbsAction = (payload: {
  data: any;
  folder: string;
  page?: number;
}): SetCurrentAgencyFolderDataBreadcrumbsAction => ({
  type: SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS,
  payload,
});

export const createAgencyFolderStructure = (
  payload: {
    structure: any;
    agency_id: string;
    parent_id: string;
  },
  callback?: Function
): CreateAgencyFolderStructure => ({
  type: CREATE_AGENCY_FOLDER_STRUCTURE,
  payload,
  callback,
});

export const setUploadFileInAgencyAction = (payload: {
  data: any;
  isFolder?: boolean;
  folders?: any;
}): SetUploadFileInAgencyAction => ({
  type: SET_UPLOAD_FILE_IN_AGENCY,
  payload,
});

export const keepFilesAndFoldersBreadcrumbsAction = (payload: {
  folderIds: string[];
}): KeepFilesAndFoldersBreadcrumbsAction => ({
  type: KEEP_FILES_AND_FOLDERS_BREADCRUMBS,
  payload,
});

export const deleteFolderFiles = (payload: {
  id: string;
}): DeleteFolderFiles => ({
  type: DELETE_FOLDER_FILES,
  payload,
});

export const removeDeletedFolderFromParent = (payload: {
  parentFolderId: string;
  folderId: string;
}): RemoveDeletedFolderFromParent => ({
  type: REMOVE_DELETED_FOLDER_FROM_PARENT,
  payload,
});

export const removeDeletedFolderFromAgency = (payload: {
  agencyId: string;
  folderId: string;
}): RemoveDeletedFolderFromAgency => ({
  type: REMOVE_DELETED_FOLDER_FROM_AGENCY,
  payload,
});

export const fetchDAMFoldersAction = (payload: {
  product_id: string;
  parent?: string;
}): FetchDAMFoldersAction => ({
  type: FETCH_DAM_FOLDERS,
  payload,
});

export const setDAMFoldersAction = (payload: any): SetDAMFoldersAction => ({
  type: SET_DAM_FOLDERS,
  payload,
});

export const removeFoldersFromBreadcrumbsWithIndex = (
  payload: string
): RemoveFoldersFromBreadcrumbsWithIndex => ({
  type: REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX,
  payload,
});

export const addNewFolderForCopy = (payload: any): AddNewFolderForCopy => ({
  type: ADD_NEW_FOLDER_FOR_COPY,
  payload,
});

export const renameTheNewlyAddedFolderForCopy = (payload: {
  id: string;
  name: string;
}): RenameTheNewlyAddedFolderForCopy => ({
  type: RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY,
  payload,
});

export const addNewFolderIntoDamBreadcrumbs = (
  payload: any
): AddNewFolderIntoDamBreadcrumbs => ({
  type: ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS,
  payload,
});

export const copyFileAndFoldersToDamAction = (payload: {
  product_id: string;
  items: any;
  foldersArray: any;
  isFolder: boolean;
}): CopyFileAndFoldersToDamAction => ({
  type: COPY_FILES_AND_FOLDERS_TO_DAM_ACTION,
  payload,
});

export const setFilesToBeUploaded = (payload: any[]): SetFilesToBeUploaded => ({
  type: SET_FILES_TO_BE_UPLOADED,
  payload,
});

export const setUploadingError = (payload: string): SetUploadingError => ({
  type: SET_UPLOADING_ERROR,
  payload,
});
