export const RESET_AGENCY_FILE_DROP = "RESET_AGENCY_FILE_DROP";
export const STOP_LOADING = "STOP_LOADING";
export const FETCH_AGENCY_LIST = "FETCH_AGENCY_LIST";
export const GET_AGENCY_LIST = "GET_AGENCY_LIST";
export const CREATE_NEW_AGENCY = "CREATE_NEW_AGENCY";
export const ADD_NEW_AGENCY = "ADD_NEW_AGENCY";
export const FETCH_AGENCY_DATA = "FETCH_AGENCY_DATA";
export const SET_AGENCY_DATA = "SET_AGENCY_DATA";
export const DELETE_AGENCY = "DELETE_AGENCY";
export const FETCH_AGENCY_FOLDERS = "FETCH_AGENCY_FOLDERS";
export const SET_AGENCY_FOLDERS = "SET_AGENCY_FOLDERS";
export const CREATE_NEW_FOLDER = "CREATE_NEW_FOLDER";
export const ADD_NEW_AGENCY_FOLDER = "ADD_NEW_AGENCY_FOLDER";
export const DELETE_AGENCY_FOLDER = "DELETE_AGENCY_FOLDER";
export const RENAME_AGENCY_FOLDER = "RENAME_AGENCY_FOLDER";
export const GET_AGENCY_SHAREABLE_KEY = "GET_AGENCY_SHAREABLE_KEY";
export const POST_AGENCY_SHAREABLE_KEY = "POST_AGENCY_SHAREABLE_KEY";
export const SET_AGENCY_SHAREABLE_KEY = "SET_AGENCY_SHAREABLE_KEY";
export const GET_AGENCY_FILES_LIST = "GET_AGENCY_FILES_LIST";
export const UPLOAD_FILE_IN_AGENCY = "UPLOAD_FILE_IN_AGENCY";
export const SET_ACTIVE_FOLDER_ID = "SET_ACTIVE_FOLDER_ID";
export const SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS =
  "SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS";
export const CREATE_AGENCY_FOLDER_STRUCTURE = "CREATE_AGENCY_FOLDER_STRUCTURE";
export const SET_UPLOAD_FILE_IN_AGENCY = "SET_UPLOAD_FILE_IN_AGENCY";
export const RESET_FILES_AND_FOLDERS_BREADCRUMBS =
  "RESET_FILES_AND_FOLDERS_BREADCRUMBS";
export const KEEP_FILES_AND_FOLDERS_BREADCRUMBS =
  "KEEP_FILES_AND_FOLDERS_BREADCRUMBS";
export const DELETE_FOLDER_FILES = "DELETE_FOLDER_FILES";
export const REMOVE_DELETED_FOLDER_FROM_PARENT =
  "REMOVE_DELETED_FOLDER_FROM_PARENT";
export const REMOVE_DELETED_FOLDER_FROM_AGENCY =
  "REMOVE_DELETED_FOLDER_FROM_AGENCY";
export const FETCH_DAM_FOLDERS = "FETCH_DAM_FOLDERS";
export const SET_DAM_FOLDERS = "SET_DAM_FOLDERS";
export const RESET_DAM_FOLDERS_BREADCRUMBS = "RESET_DAM_FOLDERS_BREADCRUMBS";
export const REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX =
  "REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX";
export const ADD_NEW_FOLDER_FOR_COPY = "ADD_NEW_FOLDER_FOR_COPY";
export const RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY =
  "RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY";
export const ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS =
  "ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS";
export const COPY_FILES_AND_FOLDERS_TO_DAM_ACTION =
  "COPY_FILES_AND_FOLDERS_TO_DAM_ACTION";
  export const SET_FILES_TO_BE_UPLOADED = "SET_FILES_TO_BE_UPLOADED";
  export const SET_UPLOADING_ERROR = "SET_UPLOADING_ERROR";

export type AgencyFileDropState = {
  loading: boolean;
  isFetchingFolders: boolean;
  agenciesList: AgencyList;
  currentAgencyFolders: SelectedAgency;
  defaultSelectedAgency: Agencies | null;
  agencyToBeShared: AgencyToBeShared;
  newAgencySelected: Agencies | null;
  activeFolderId: string | null;
  currentAgencyFolderData: CurrentAgencyFolderData;
  filesDataBreadcrumbs: FilesDataBreadcrumbs[];
  folderDataBreadcrumbs: FilesDataBreadcrumbs[];
  foldersOfDam: any;
  damFoldersBreadcrumbs: any;
  filesToBeUploaded : any[]
};

export type AgencyList = {
  pages: number;
  page_size: number;
  current_page: number;
  count: number;
  results: Agencies[];
};

export type SelectedAgency = {
  count: number;
  current_page: number;
  pages: number;
  page_size: number;
  parent: any;
  results: AgencyFolder[];
};

export type Agencies = {
  agency_name: string;
  created_at: Date;
  id: string;
  is_trashed: boolean;
  modified_at: Date;
  trashed_at: null;
  workspace: string;
  folders_count?: null | number;
};

export type AgencyToBeShared = {
  shareable_link_key: string;
  password: string | null;
  expiration_date: string | null;
  password_flag: boolean;
};

export type AgencyFolder = {
  agency_id: string;
  content_count: number;
  id: number;
  name: string;
  parent: null;
  subfolder_count: number;
  type: string;
  file_count?: number
};

export type CurrentAgencyFolderData = {
  pages: number;
  page_size: number;
  current_page: number;
  count: number;
  parent?: any;
  results: any[];
};

export type FilesDataBreadcrumbs = {
  data: CurrentAgencyFolderData;
  folder: string;
};
export interface ResetAgencyFileDropAction {
  type: typeof RESET_AGENCY_FILE_DROP;
}

export interface StopLoadingOnErrorAction {
  type: typeof STOP_LOADING;
}

export interface ResetFilesAndFoldersBreadcrumbsAction {
  type: typeof RESET_FILES_AND_FOLDERS_BREADCRUMBS;
}

export interface ResetDamFoldersBreadcrumbs {
  type: typeof RESET_DAM_FOLDERS_BREADCRUMBS;
}

export type FetchAgencyList = {
  type: typeof FETCH_AGENCY_LIST;
  payload: {
    workspace: string;
    page?: number;
  };
};

export type GetAgencyList = {
  type: typeof GET_AGENCY_LIST;
  payload: { data: AgencyList; shouldLoading?: boolean; page: number };
};

export type CreateNewAgency = {
  type: typeof CREATE_NEW_AGENCY;
  payload: {
    agency_name: string;
    workspace: string;
    parent?: string;
  };
};

export type AddNewAgency = {
  type: typeof ADD_NEW_AGENCY;
  payload: Agencies;
};

export type FetchAgencyData = {
  type: typeof FETCH_AGENCY_DATA;
  payload: string;
};

export type DeleteAgency = {
  type: typeof DELETE_AGENCY;
  payload: {
    id: string;
    workspace: string;
  };
};

export type FetchAgencyFolders = {
  type: typeof FETCH_AGENCY_FOLDERS;
  payload: {
    agency_id: string;
    page?: number;
    isAgencyChanged?: boolean;
    parent?: string;
    folderToBeRemoved?: string;
  };
};

export type SetAgencyFolders = {
  type: typeof SET_AGENCY_FOLDERS;
  payload: {
    data: SelectedAgency;
    page?: number;
    parent?: string;
    folderToBeRemoved?: string;
  };
};

export type CreateNewFolder = {
  type: typeof CREATE_NEW_FOLDER;
  payload: {
    name: string;
    agency_id: string;
    parent?: string;
  };
};

export type AddNewAgencyFolder = {
  type: typeof ADD_NEW_AGENCY_FOLDER;
  payload: AgencyFolder;
};

export type DeleteAgencyFolder = {
  type: typeof DELETE_AGENCY_FOLDER;
  payload: {
    id: string;
    agencyId: string;
  };
};

export type RenameAgencyFolder = {
  type: typeof RENAME_AGENCY_FOLDER;
  payload: {
    id: string;
    name: string;
  };
};

export type SetAgencyData = {
  type: typeof SET_AGENCY_DATA;
  payload: Agencies;
};

export type GetAgencyShareableKey = {
  type: typeof GET_AGENCY_SHAREABLE_KEY;
  payload: {
    password_flag: boolean;
    agency: string;
  };
};

export type PostAgencyShareableKey = {
  type: typeof POST_AGENCY_SHAREABLE_KEY;
  payload: {
    password_flag: boolean;
    agency: string;
  };
};

export type SetAgencyShareableKey = {
  type: typeof SET_AGENCY_SHAREABLE_KEY;
  payload: AgencyToBeShared;
};

export type GetAgencyFilesList = {
  type: typeof GET_AGENCY_FILES_LIST;
  payload: { agency_id: string; folders: string; page?: number };
};

export type UploadFileInAgencyAction = {
  type: typeof UPLOAD_FILE_IN_AGENCY;
  payload: {
    file: any;
    agency_id: string;
    folder: string;
    title: string;
    file_type: string;
    isFolder: boolean;
    folders: any;
    fileType: string;
  };
};

export type SetActiveFolderId = {
  type: typeof SET_ACTIVE_FOLDER_ID;
  payload: {
    folderId: string;
  };
};

export type SetCurrentAgencyFolderDataBreadcrumbsAction = {
  type: typeof SET_CURRENT_AGENCY_FOLDERS_DATA_BREADCRUMBS;
  payload: { data: any; folder: string; page?: number };
};

export type CreateAgencyFolderStructure = {
  type: typeof CREATE_AGENCY_FOLDER_STRUCTURE;
  payload: {
    structure: any;
    agency_id: string;
    parent_id: string;
  };
  callback?: Function;
};

export type SetUploadFileInAgencyAction = {
  type: typeof SET_UPLOAD_FILE_IN_AGENCY;
  payload: { data: any; isFolder?: boolean; folders?: any };
};

export type KeepFilesAndFoldersBreadcrumbsAction = {
  type: typeof KEEP_FILES_AND_FOLDERS_BREADCRUMBS;
  payload: {
    folderIds: string[];
  };
};

export type DeleteFolderFiles = {
  type: typeof DELETE_FOLDER_FILES;
  payload: {
    id: string;
  };
};

export type RemoveDeletedFolderFromParent = {
  type: typeof REMOVE_DELETED_FOLDER_FROM_PARENT;
  payload: {
    parentFolderId: string;
    folderId: string;
  };
};

export type RemoveDeletedFolderFromAgency = {
  type: typeof REMOVE_DELETED_FOLDER_FROM_AGENCY;
  payload: {
    agencyId: string;
    folderId: string;
  };
};

export type FetchDAMFoldersAction = {
  type: typeof FETCH_DAM_FOLDERS;
  payload: {
    product_id: string;
    parent?: string;
  };
};

export type SetDAMFoldersAction = {
  type: typeof SET_DAM_FOLDERS;
  payload: any;
};

export type RemoveFoldersFromBreadcrumbsWithIndex = {
  type: typeof REMOVE_FOLDERS_FROM_BREADCRUMBS_WITH_INDEX;
  payload: any;
};

export type AddNewFolderForCopy = {
  type: typeof ADD_NEW_FOLDER_FOR_COPY;
  payload: any;
};

export type RenameTheNewlyAddedFolderForCopy = {
  type: typeof RENAME_THE_NEWLY_ADDED_FOLDER_FOR_COPY;
  payload: {
    id: string;
    name: string;
  };
};

export type AddNewFolderIntoDamBreadcrumbs = {
  type: typeof ADD_NEW_FOLDER_INTO_DAM_BREADCRUMBS;
  payload: any;
};

export type CopyFileAndFoldersToDamAction = {
  type: typeof COPY_FILES_AND_FOLDERS_TO_DAM_ACTION;
  payload: {
    product_id: string;
    items: any;
    foldersArray: any;
    isFolder: boolean;
  };
};


export type SetFilesToBeUploaded = {
  type: typeof SET_FILES_TO_BE_UPLOADED;
  payload: any[];
};

export type SetUploadingError = {
  type: typeof SET_UPLOADING_ERROR;
  payload: string;
};