import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgencyFileDropState } from "../../store/types";
import { setFilesToBeUploaded } from "../../store/actions/agencyFile";
import Modal from "../shared/Modal";
// import { TutorialPopUp } from '../shared/TutorialPopUp';
import {
  detectFileIconType,
  detectProjectType,
} from "../../Pages/AgencyFileDrop/utils";
import CloseIcon from "../../assets/svg/CloseIcon.svg";
import MinimizeIcon from "../../assets/svg/MinimizeIcon.svg";
import MaximizeIcon from "../../assets/svg/MaximizeIcon.svg";
import CompleteUploadIcon from "../../assets/svg/CompleteUploadIcon.svg";
import UploadErrorIcon from "../../assets/svg/uploadErrorIcon.svg";

import "./index.scss";

// root level modals
const ProgressiveAgencyUploadModal: React.FC = () => {
    const dispatch = useDispatch();
  const { filesToBeUploaded } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => ({
      ...state.agencyFileDrop,
    })
  );
const isAnyFileIsIncomplete = filesToBeUploaded?.filter(item => item?.status === "INCOMPLETE")
  const [isMinized, setIsMinimized] = useState(false);
  const handleMinimizeModal = useCallback(() => {
    setIsMinimized(!isMinized);
  }, [isMinized]);

const handleCloseModal = useCallback(()=>{
    dispatch(setFilesToBeUploaded([]))
},[dispatch])

  return !!filesToBeUploaded?.length ?(
    <Modal>
      <div className="action-popups-container right-side agency_files_upload">
        {/* we are using three different component because we need three different popup */}
        <div className="download_files_box">
          <div className="content_wrapper">
            <div className="content_title">
              {!!isAnyFileIsIncomplete.length ? `Uploading ${filesToBeUploaded?.length} items` : `${filesToBeUploaded?.length} items uploaded`}
              
            </div>
            <div className="modal_action_buttons">
              {!!isMinized ? (
                <div className="action_icon" onClick={handleMinimizeModal}>
                  <img src={MaximizeIcon} alt="Maximize Icon" />
                </div>
              ) : (
                <div className="action_icon" onClick={handleMinimizeModal}>
                  <img src={MinimizeIcon} alt="Minimize Icon" />
                </div>
              )}
              <div className="action_icon" onClick={handleCloseModal}>
                <img src={CloseIcon} alt="Close Icon" />
              </div>
            </div>
          </div>
              {!isMinized ? (
          <div style={{ paddingTop: "1rem", background: "#fff" }}>
            <div className="files_content_wrapper">
                <div>
                  {filesToBeUploaded?.map((file, fileIndex) => {
                    const extension: string =
                      file?.name
                        ?.split(".")
                        ?.pop()
                        ?.toLowerCase() || "";
                    const hasExtension: boolean =
                      file?.name?.split(".")?.length > 1;
                    const url: string =
                      file.type?.includes("image/") && extension !== "psd"
                        ? file.thumbUrl
                        : detectProjectType({ ...file, file_type: extension });

                    return (
                      <div key={fileIndex} className="uploaded-file">
                        <div className="uploaded_file_content_wrapper">
                          <div className="fileTypeIconWrapper uplaoded_file_content">
                            <div>
                              <img
                                src={detectFileIconType({
                                  ...file,
                                  file_url: url,
                                  file_type: extension,
                                })}
                                alt="File Icon"
                                className="uploadedFileIcon"
                              />
                            </div>
                            <div className="file_title">{file?.name}</div>
                          </div>
                          <div>
                            {file.status === "INCOMPLETE" ? (
                              <div id="loader" />
                            ) : (
                              <div>
                                {file.status === "ERROR" ? 
                                <img
                                src={UploadErrorIcon}
                                alt="Upload Error"
                              />
                                :
                                
                                <img
                                  src={CompleteUploadIcon}
                                  alt="Upload Completed"
                                />
                                }
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
            </div>
          </div>
              ) : (
                <></>
              )}
        </div>
      </div>
    </Modal>
  ) : <></>;
};

export default ProgressiveAgencyUploadModal;
