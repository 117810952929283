import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteFolderFiles,
  fetchAgencyFolders,
  getAgencyFilesList,
} from "../../store/actions/agencyFile";
import { AgencyFileDropState, PublicAgencyLinkState } from "../../store/types";

import {
  detectFileIconType,
  detectProjectType,
  formatDateForUploadedFile,
  getDataFromParams,
} from "../AgencyFileDrop/utils";
import { IMAGE_BASE_URL } from "../../utilities/paths";
import Img from "../../components/ImageLoader/ImageLoader";
import { getUrlProperties } from "../../utilities/common-function";
import {} from "../../components/shared/UploadDesignsDrawer/utils";
import AgencyFolderItem from "../AgencyFileDrop/components/AgencyFolderItem";
import UploadFilesAndFolderForAgency from "../PublicAgencyView/components/UploadFilesAndFolder";

import emptyFilesLogo from "../../assets/svg/emptyFolderFilesUploadIcon.svg";
import emptyAgencyFolderIon from "../../assets/svg/emptyAgencyFolderIcon.svg";
import dropdownIcon from "../../assets/svg/dropdown-icon.svg";
import copyFolderIcon from "../../assets/svg/CopyFolderIcon.svg";
import downloadIcon from "../../assets/svg/downloadIcon.svg";
import deleteIcon from "../../assets/svg/delete_agency.svg";

import "./index.scss";
import { FullScreenSpinner } from "../../components/shared/Spinner";
import { Dropdown, Menu, MenuItem } from "pyxis-ui-kit";
import { Tooltip } from "antd";
import DeleteResourceModal from "../AgencyFileDrop/components/DeleteResourceModal";
import CopyResourceModal from "../AgencyFileDrop/components/CopyResourceModal";

const AgencyFileView = ({ agency_id, isForPublic = true }) => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: {
      agencyPublicLink: PublicAgencyLinkState;
      agencyFileDrop: AgencyFileDropState;
    }) => ({
      ...state.agencyPublicLink,
      ...state.agencyFileDrop,
    })
  );

  const { baseUrl, search } = getUrlProperties();
  const folder = getDataFromParams(search, "folder");
  const folderList = folder?.split("-");

  const { filesDataBreadcrumbs, folderDataBreadcrumbs, loading } = state;

  const requiredFolderFiles = Array.isArray(filesDataBreadcrumbs)
    ? filesDataBreadcrumbs.find(
        (item: any) =>
          String(item.folder) === String(folderList?.[folderList?.length - 1])
      )
    : null;

  const requiredFolderData = Array.isArray(folderDataBreadcrumbs)
    ? folderDataBreadcrumbs.find(
        (item: any) =>
          String(item.folder) === String(folderList?.[folderList?.length - 1])
      )
    : null;

  const [hasMoreFiles, setHasMoreFiles] = useState<boolean>(
    requiredFolderFiles?.data?.results?.length
      ? requiredFolderFiles.data.results.length <
          (requiredFolderFiles.data.count || 0)
      : false
  );

  const [hasMoreFolders, setHasMoreFolders] = useState<boolean>(
    requiredFolderData?.data?.results?.length < requiredFolderData?.data?.count
  );

  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState<boolean>(
    false
  );

  const [isCopyFolderModalOpen, setIsCopyFolderModalOpen] = useState<boolean>(
    false
  );

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    setHasMoreFiles(
      requiredFolderFiles?.data?.results?.length <
        requiredFolderFiles?.data?.count
    );

    setHasMoreFolders(
      requiredFolderData?.data?.results?.length <
        requiredFolderData?.data?.count
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFolderFiles, requiredFolderData]);
  const handleFolderClick = useCallback(
    (folder) => {
      let newurl = baseUrl + `?${search}-${folder.id}`;
      window.history.pushState({ path: newurl }, "", newurl);
      dispatch(getAgencyFilesList({ agency_id, folders: folder.id }));
      dispatch(fetchAgencyFolders({ agency_id, parent: folder.id }));
    },
    [agency_id, baseUrl, dispatch, search]
  );

  const fetchMoreFiles = useCallback(() => {
    if (
      requiredFolderFiles.data.current_page >= requiredFolderFiles.data.pages
    ) {
      setHasMoreFiles(false);
      return;
    }

    const requiredData = {
      agency_id,
      page: Number(requiredFolderFiles.data.current_page) + 1,
      folders: folderList[folderList.length - 1],
    };
    dispatch(getAgencyFilesList(requiredData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFolderFiles, dispatch]);

  const fetchMoreFolders = useCallback(() => {
    if (requiredFolderData.data.current_page >= requiredFolderData.data.pages) {
      setHasMoreFolders(false);
      return;
    }

    const requiredData = {
      agency_id,
      page: Number(requiredFolderData.data.current_page) + 1,
      parent: folderList[folderList.length - 1],
    };
    dispatch(fetchAgencyFolders(requiredData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFolderData, dispatch]);

  const handleMenuClick = useCallback((key: string, file) => {
    setSelectedFile(file);
    switch (key) {
      case "copyFile":
        setIsCopyFolderModalOpen(true);
        break;

      case "downloadFile":
        const downloadUrl = IMAGE_BASE_URL + file.file_url;

        // Create an anchor element
        const link = document.createElement("a");

        // Set the download attribute with a filename (optional)
        link.download = file.file_name || "downloaded_file";

        // Set the href to the file URL
        link.href = downloadUrl;

        // Append the link to the document
        document.body.appendChild(link);

        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
        break;

      case "deleteFile":
        setIsDeleteFileModalOpen(true);
        break;

      default:
        return;
    }

    setActiveDropdown(null); // Close dropdown after selection
  }, []);

  const handleFolderFileDelete = useCallback(() => {
    dispatch(
      deleteFolderFiles({
        id: String(selectedFile.id),
      })
    );
    setIsDeleteFileModalOpen(false);
  }, [dispatch, selectedFile]);

  const menu = (file) => (
    <Menu
      style={{ width: "22rem", borderRadius: "1rem" }}
      onClick={(e) => handleMenuClick(String(e.key), file)} // Cast key to string
    >
      <MenuItem key="copyFile" className="dropdown__item">
        <img src={copyFolderIcon} alt="Copy file icon" />
        Copy to my files
      </MenuItem>
      <MenuItem key="downloadFile" className="dropdown__item">
        <img src={downloadIcon} alt="Download file icon" />
        Download file
      </MenuItem>
      <MenuItem
        key="deleteFile"
        className="dropdown__item dropdown__item--delete"
      >
        <img src={deleteIcon} alt="Delete file icon" />
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {loading && <FullScreenSpinner />}
      <div
        style={{
          height: `calc(100vh - 9rem)`,
          overflow: "scroll",
          padding: "0rem 2rem",
        }}
        id="files_folders_wrapper"
      >
        {!requiredFolderData?.data?.results?.length &&
        !requiredFolderFiles?.data?.results?.length &&
        folderList?.length === 1 ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {!!isForPublic ? (
              <>
                <img src={emptyFilesLogo} alt="empty_files_icon" />
                <div
                  style={{ fontSize: "1.8rem", fontWeight: 600, color: "#000" }}
                >
                  Drag and drop your files or click upload
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <UploadFilesAndFolderForAgency />
                </div>
              </>
            ) : (
              <>
                <img src={emptyAgencyFolderIon} alt="empty_files_icon" />
                <div
                  style={{ fontSize: "1.8rem", fontWeight: 600, color: "#000" }}
                >
                  No Files and Folder Uploaded Yet!
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {!!requiredFolderData?.data?.results?.length ? (
                <div
                  className="folders_wrapper"
                  id="folders_wrapper"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: 500,
                      color: "#7D899C",
                      marginBottom: "1rem",
                    }}
                  >
                    Folders
                  </div>
                  <InfiniteScroll
                    dataLength={requiredFolderData?.data?.results.length}
                    next={fetchMoreFolders}
                    hasMore={hasMoreFolders}
                    loader={""}
                    scrollableTarget="files_folders_wrapper"
                  >
                    <div className="folders-content">
                      {requiredFolderData?.data.results.map((folder, index) => (
                        <AgencyFolderItem
                          folder={folder}
                          index={index}
                          handleFolderClick={handleFolderClick}
                          isForPublic={isForPublic}
                        />
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              ) : (
                <></>
              )}

              {!!requiredFolderFiles?.data?.results.length ? (
                <>
                  <div
                    style={{
                      fontSize: "1.4rem",
                      fontWeight: 500,
                      color: "#7D899C",
                      marginTop: "1rem",
                    }}
                  >
                    Files
                  </div>
                  <InfiniteScroll
                    dataLength={requiredFolderFiles?.data?.results.length}
                    next={fetchMoreFiles}
                    hasMore={hasMoreFiles}
                    loader={""}
                    scrollableTarget="files_folders_wrapper"
                  >
                    <div className="grid_view">
                      {requiredFolderFiles?.data?.results.map(
                        (file, fileIndex) => (
                          <>
                            <div key={fileIndex}>
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "-20rem",
                                    left: "2rem",
                                    padding: "0.5rem",
                                    background: "#FFFFFF",
                                    border: "1px solid #ECEEF1",
                                    borderRadius: "0.6rem",
                                  }}
                                >
                                  <img
                                    src={detectFileIconType({
                                      ...file,
                                      file_url: IMAGE_BASE_URL + file.file_url,
                                    })}
                                    alt="DropDown Icon"
                                  />
                                </div>
                              </div>
                              <div className="file_wrapper">
                                <Img
                                  className=" file_image"
                                  src={detectProjectType({
                                    ...file,
                                    file_url: IMAGE_BASE_URL + file.file_url,
                                  })}
                                />
                              </div>
                              <div className="file_content_wrapper">
                                <div
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Tooltip
                                    placement="bottomRight"
                                    title={file.title}
                                  >
                                    <div className="image_title">
                                      {file.title}
                                    </div>
                                  </Tooltip>
                                  <div className="image_desc">
                                    {!isForPublic ? "Uploaded on " : ""}
                                    {formatDateForUploadedFile(file.created_at)}
                                  </div>
                                </div>

                                <div>
                                  {!isForPublic ? (
                                    <Dropdown
                                      overlay={menu(file)}
                                      visible={activeDropdown === fileIndex} // Check if this dropdown should be visible
                                      onVisibleChange={(visible) => {
                                        setActiveDropdown(
                                          visible ? fileIndex : null
                                        );
                                      }}
                                      trigger={["click"]} // Opens dropdown on click
                                      placement="bottomRight"
                                    >
                                      <div style={{ cursor: "pointer" }}>
                                        <img
                                          src={dropdownIcon}
                                          alt="DropDown Icon"
                                        />
                                      </div>
                                    </Dropdown>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  </InfiniteScroll>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>

      <DeleteResourceModal
        isModalOpen={isDeleteFileModalOpen}
        setModalOpen={setIsDeleteFileModalOpen}
        modalTitle={"Delete File?"}
        modalDesc={"Are you sure you want to delete this file."}
        handleDeleteClick={handleFolderFileDelete}
      />

      <CopyResourceModal
        isModalOpen={isCopyFolderModalOpen}
        setModalOpen={setIsCopyFolderModalOpen}
        itemToBeCopied={selectedFile}
      />
    </>
  );
};

export default AgencyFileView;
