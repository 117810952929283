import React, { useState } from "react";
import { Button, Menu, MenuItem, Dropdown } from "pyxis-ui-kit";

import fileIcon from "../../../../assets/svg/file-upload.svg";
import folderIcon from "../../../../assets/svg/folder-upload.svg";
import uploadIcon from "../../../../assets/svg/uploadFolderIcon.svg";
import UplaodFilesOrFolderModal from "../UplaodFilesOrFolderModal";

const UploadFilesAndFolderForAgency = () => {
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState("");
  const [isUploadModalVisible, setIsUploadModalVisible] = useState<boolean>(
    false
  );

  const handleAgencyUploadClick = () => {
    setActiveDropdown(!activeDropdown);
  };

  const handleUploadTypeClick = (type) => {
    setUploadType(type);
    setIsUploadModalVisible(true);
    setActiveDropdown(false);
  };

  const menu = () => (
    <Menu
      style={{
        width: "100%",
        borderRadius: "1rem",
        borderColor: "#E0E3E5",
      }}
    >
      <MenuItem
        key="uploadFile"
        className="dropdown__item"
        onClick={()=>handleUploadTypeClick("file")}
      >
        <img src={fileIcon} alt="File icon" />
        Upload files
      </MenuItem>
      <MenuItem
        key="uploadFolder"
        className="dropdown__item"
        onClick={() =>handleUploadTypeClick("folder")}
      >
        <img src={folderIcon} alt="Folder icon" />
        Upload folder
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <div style={{ margin: "0rem 2rem" }}>
        <Dropdown
          overlay={menu}
          visible={activeDropdown}
          trigger={["click"]} // Opens dropdown on click
          placement="bottomRight"
          onVisibleChange={handleAgencyUploadClick}
        >
          <Button
            btnsize="sm"
            className="agency-header__button agency-header__button--share"
            onClick={handleAgencyUploadClick}
          >
            <span className="agency-header__button-text agency-header__button--share_text">
              <img src={uploadIcon} alt="delete agency" />
              &nbsp;&nbsp;Upload
            </span>
          </Button>
        </Dropdown>
      </div>
      <UplaodFilesOrFolderModal
        isModalOpen={isUploadModalVisible}
        setModalOpen={setIsUploadModalVisible}
        uploadType={uploadType}
        setUploadType={setUploadType}
      />
    </>
  );
};

export default UploadFilesAndFolderForAgency;
