import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Input, Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { AgencyFileDropState } from "../../../../store/types";
import {
  addNewFolderForCopy,
  addNewFolderIntoDamBreadcrumbs,
  copyFileAndFoldersToDamAction,
  fetchDAMFoldersAction,
  removeFoldersFromBreadcrumbsWithIndex,
  renameTheNewlyAddedFolderForCopy,
  resetDamFoldersBreadcrumbs,
} from "../../../../store/actions/agencyFile";

import folderIcon from "../../../../assets/svg/agency-folder-icon.svg";

import "./index.scss";
import { Button, Dropdown, Menu, MenuItem } from "pyxis-ui-kit";
import { uniqueId } from "lodash";

interface CopyResourceModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  itemToBeCopied: any;
  isFolder?: boolean;
}
const CopyResourceModal: FC<CopyResourceModalProps> = ({
  isModalOpen,
  setModalOpen,
  itemToBeCopied,
  isFolder = false,
}) => {
  const dispatch = useDispatch();
  const workspace = useSelector(({ roles }: any) => roles.selectedProductId);
  const { foldersOfDam, damFoldersBreadcrumbs } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => state.agencyFileDrop
  );
  let clickTimeout = null;
  
  const [folders, setFolders] = useState(foldersOfDam);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [editingFolderId, setEditingFolderId] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
    dispatch(resetDamFoldersBreadcrumbs());
  }, [dispatch, setModalOpen]);

  useEffect(() => {
    setFolders(foldersOfDam);
  }, [foldersOfDam]);

  useEffect(() => {
    if (!!isModalOpen) {
      dispatch(fetchDAMFoldersAction({ product_id: workspace }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const toggleDropdown = useCallback(
    (event, folder) => {
      // event.preventDefault();
      if (!!folder?.isNewFolder) {
        dispatch(addNewFolderIntoDamBreadcrumbs(folder));
        return;
      }
      dispatch(
        fetchDAMFoldersAction({ product_id: workspace, parent: folder.id })
      );
      setEditingFolderId(null);
    },
    [dispatch, workspace]
  );

  const handleFolderBreadcrumbClick = useCallback(
    (folderId) => {
      const breadcrumbIndex = damFoldersBreadcrumbs.findIndex(
        (item) => item.id === folderId
      );
      dispatch(removeFoldersFromBreadcrumbsWithIndex(breadcrumbIndex));
      setEditingFolderId(null);
    },
    [damFoldersBreadcrumbs, dispatch]
  );

  const breadcrumbsMenu = () => {
    return (
      <Menu style={{ borderRadius: "1rem" }}>
        {damFoldersBreadcrumbs
          .slice(2, damFoldersBreadcrumbs.length - 1)
          .map((folder) => (
            <MenuItem
              key={folder.id}
              onClick={() => handleFolderBreadcrumbClick(folder.id)}
            >
              {folder.name}
            </MenuItem>
          ))}
      </Menu>
    );
  };

  const handleHomeClick = useCallback(() => {
    if (!damFoldersBreadcrumbs?.length) return;
    dispatch(resetDamFoldersBreadcrumbs());
    setEditingFolderId(null);
  }, [damFoldersBreadcrumbs, dispatch]);

  const handleCreateNewFolder = useCallback(() => {
    const newFolder = {
      name: "New Folder",
      isNewFolder: true,
      id: uniqueId("new"),
      parent: !damFoldersBreadcrumbs?.length
        ? null
        : damFoldersBreadcrumbs[damFoldersBreadcrumbs?.length - 1]?.id,
    };
    dispatch(addNewFolderForCopy(newFolder));
  }, [damFoldersBreadcrumbs, dispatch]);
  

  const handleChangeNameOfNewFolder = (item) => {
    if (!!item.isNewFolder) {
      setEditingFolderId(item.id);
      setNewFolderName(item.name);
    }
  };

  const handleInputChange = (e) => {
    setNewFolderName(e.target.value);
  };

  const handleChangeName = useCallback(
    (item) => {
      // Update the folder name logic
      dispatch(
        renameTheNewlyAddedFolderForCopy({ id: item.id, name: newFolderName })
      );
      setEditingFolderId(null); // Exit edit mode
    },
    [dispatch, newFolderName]
  );

  const handleCopyAction = useCallback(() => {
    dispatch(
      copyFileAndFoldersToDamAction({
        product_id: workspace,
        foldersArray: damFoldersBreadcrumbs,
        items: itemToBeCopied,
        isFolder,
      })
    );
    handleCancelButton();
  }, [damFoldersBreadcrumbs, dispatch, handleCancelButton, isFolder, itemToBeCopied, workspace]);

  const handleClick = (event,item) => {
    if (clickTimeout) return; // Ignore if waiting for a double-click

    clickTimeout = setTimeout(() => {
      console.log("Single Click Action"); // Run the click function
      handleChangeNameOfNewFolder(item)
      clickTimeout = null;
    }, 250); // Adjust timeout to suit your needs
  };

  const handleDoubleClick = (event,item) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout); // Cancel the single click
      clickTimeout = null;
    }
    toggleDropdown(event, item);
    console.log("Double Click Action"); // Run double-click function
  };

  return (
    <>
      <Modal
        centered
        visible={isModalOpen}
        footer={null}
        className="copy_modal"
        onCancel={handleCancelButton}
      >
        <div className="modal-content-title">
          Copy “{itemToBeCopied?.name || itemToBeCopied?.title}” to my files
        </div>
        <div className="modal-content-desc">
          Navigate to a folder where you want to copy your file
        </div>

        <div
          style={{
            cursor: "pointer",
            marginTop: "3rem",
            fontSize: "1.2rem",
            fontWeight: !!damFoldersBreadcrumbs?.length ? 400 : 500,
            color: !!damFoldersBreadcrumbs?.length ? "#5C6674" : "#0869FB",
            display: "flex",
          }}
        >
          <div onClick={handleHomeClick}>Home(all files)</div>

          {!!damFoldersBreadcrumbs?.length && (
            <>
              {damFoldersBreadcrumbs?.length > 3 ? (
                <>
                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight:
                          damFoldersBreadcrumbs.length === 1 ? 500 : 400,
                        color:
                          damFoldersBreadcrumbs.length === 1
                            ? "#0869FB"
                            : "#5C6674",
                      }}
                      onClick={() =>
                        handleFolderBreadcrumbClick(damFoldersBreadcrumbs[0].id)
                      }
                    >
                      {damFoldersBreadcrumbs[0].name}
                    </div>
                  </div>

                  {/* Show the second breadcrumb */}
                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight: 400,
                        color: "#5C6674",
                      }}
                      onClick={() =>
                        handleFolderBreadcrumbClick(damFoldersBreadcrumbs[1].id)
                      }
                    >
                      {damFoldersBreadcrumbs[1].name}
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>

                    <Dropdown
                      overlay={breadcrumbsMenu}
                      visible={activeDropdown} // Check if this dropdown should be visible
                      onVisibleChange={(visible) => {
                        setActiveDropdown(visible); // Show dropdown only for the active folder
                      }}
                      trigger={["click"]} // Opens dropdown on click
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          fontWeight: 400,
                          color: "#5C6674",
                        }}
                      >
                        ...
                      </div>
                    </Dropdown>
                  </div>

                  <div style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight: 500,
                        color: "#0869FB",
                      }}
                      onClick={() =>
                        handleFolderBreadcrumbClick(
                          damFoldersBreadcrumbs[
                            damFoldersBreadcrumbs?.length - 1
                          ].id
                        )
                      }
                    >
                      {
                        damFoldersBreadcrumbs[damFoldersBreadcrumbs?.length - 1]
                          .name
                      }
                    </div>
                  </div>
                </>
              ) : (
                damFoldersBreadcrumbs.map((folder, index) => (
                  <div key={folder.id} style={{ display: "flex" }}>
                    <span className="path">
                      &nbsp;&nbsp;{" > "}&nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        fontWeight:
                          index + 1 === damFoldersBreadcrumbs?.length
                            ? 500
                            : 400,
                        color:
                          index + 1 === damFoldersBreadcrumbs?.length
                            ? "#0869FB"
                            : "#5C6674",
                      }}
                      onClick={() => handleFolderBreadcrumbClick(folder.id)}
                    >
                      {folder.name}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
        {!!folders?.length && (
          <div
            style={{ height: "35rem", overflow: "scroll", marginTop: "1rem" }}
          >
            {folders[folders.length - 1]?.results?.map((item) => (
              <div
                key={item.id}
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid #ECEEF1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0 1rem",
                }}
                onDoubleClick={(e) => handleDoubleClick(e,item)}
                onClick={(e) => handleClick(e,item)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",

                    padding: "1rem",
                    gap: "2rem",
                  }}
                >
                  <img src={folderIcon} alt="Folder Icon" />
                  {editingFolderId === item.id ? (
                    <Input
                      className="input-field"
                      type="text"
                      value={newFolderName}
                      onChange={handleInputChange}
                      onBlur={() => handleChangeName(item)}
                      onPressEnter={() => handleChangeName(item)}
                      autoFocus
                    />
                  ) : (
                    item.name
                  )}
                </div>
                <div style={{ color: "#7D899C" }}>{">"}</div>
              </div>
            ))}
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button
              btnsize="sm"
              className="create_new_folder_to_copy"
              onClick={handleCreateNewFolder}
            >
              <span>+&nbsp;&nbsp;&nbsp; New Folder</span>
            </Button>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button
              className="button cancel-button copy_action_buttons"
              onClick={handleCancelButton}
            >
              Cancel
            </Button>
            <Button
              className="button create-button copy_action_buttons"
              type="primary"
              onClick={handleCopyAction}
            >
              Copy here
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CopyResourceModal;
