import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Modal, Tooltip } from "antd";
import { uniqueId } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "pyxis-ui-kit";


import {
  AgencyFileDropState,
  PublicAgencyLinkState,
} from "../../../../store/types";
import { setFilesToBeUploaded } from "../../../../store/actions/agencyFile";

import {
  getStructure,
  getThumbNail,
  getTreeStructure,
} from "../../../../components/shared/UploadDesignsDrawer/utils";
import {
  detectFileIconType,
  detectProjectType,
  getDataFromParams,
  handleSubmit,
} from "../../../AgencyFileDrop/utils";
import { getUrlProperties } from "../../../../utilities/common-function";

import uploadIcon from "../../../../assets/svg/empty_folders.svg";
import RandomPreview from "../../../../assets/img/random_preview.png";
import folderIcon from "../../../../assets/svg/agency-folder-icon.svg";
import removeIcon from "../../../../assets/svg/removeUploadedFileIcon.svg";
import "./index.scss";
interface UplaodFilesOrFolderModalProps {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  uploadType: string;
  setUploadType: Function;
}

const UplaodFilesOrFolderModal: FC<UplaodFilesOrFolderModalProps> = ({
  isModalOpen,
  setModalOpen,
  uploadType,
  setUploadType,
}) => {
  const dispatch = useDispatch();
  const state = useSelector(
    (state: {
      agencyPublicLink: PublicAgencyLinkState;
      agencyFileDrop: AgencyFileDropState;
    }) => ({
      ...state.agencyPublicLink,
      ...state.agencyFileDrop,
    })
  );
  const { agency_id } = state;
  const { search } = getUrlProperties();

  const folder = getDataFromParams(search, "folder");
  const foldersList = folder.split("-");
  const folderUpload = useRef<any>(null);
  const fileUpload = useRef<any>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  useEffect(() => {
    let folderNode: any = folderUpload.current;
  
    if (folderNode) {
      if (uploadType === "folder" && !!isModalOpen) {
        folderNode.directory = true;
        folderNode.webkitdirectory = true;
      } else {
        folderNode.removeAttribute("directory");
        folderNode.removeAttribute("webkitdirectory");
      }
    }
  }, [isModalOpen, uploadType]);
  

  const handleChange = useCallback((e: any) => {
    let files: any[] = Array.from(e?.target?.files || []); // Convert FileList to an array

    for (let i = 0; i < files.length; i++) {
      files[i].uid = uniqueId("file_");
      files[i].thumbUrl = URL.createObjectURL(files[i]);
      files[i].parent = 0;
    }
    setFileList((f: any) => [...f, ...files]);
    // handleSubmit(files, foldersList[0], agency_id, dispatch);
  }, []);

  const handleFolderUpload = (e: any) => {
    let files: any[] = Array.isArray(e) ? e : Array.from(e?.target?.files);
    // Removing hidden files
    files = files.filter((file: any) => !file.name.startsWith("."));
    if ((Array.isArray(e) && e) || e?.target?.files?.length) {
      Promise.all(files.map(getThumbNail)).then((updatedFiles: any) => {
        let tree: any = getTreeStructure(files);
        let { structure, hashedStructure }: any = getStructure(
          tree,
          {},
          {},
          ""
        );
        let rootFolder: string = Object.keys(tree)[0];
        let size: number = files.reduce((acc: any, f: any) => f.size + acc, 0);
        setFileList((f: any) => [
          ...f,
          {
            name: rootFolder,
            size,
            type: "folder",
            file: tree,
            files,
            structure,
            hashedStructure,
            uid: uniqueId("folder_"),
          },
        ]);
      });
    }
  };

  const handleCancelButton = useCallback(() => {
    setModalOpen(false);
    setFileList([]);
    setUploadType("");
  }, [setModalOpen, setUploadType]);

  const handleUploadFilesOrFolders = useCallback(() => {
    const isFolderUploading = uploadType === "folder";
    if(!!isFolderUploading){
      for(let i = 0; i<fileList.length ; i++){
        handleSubmit(
          [fileList[i]],
          foldersList[0],
          agency_id,
          dispatch,
          isFolderUploading
        );
      }
    }else{

      handleSubmit(
        fileList,
        foldersList[0],
        agency_id,
        dispatch,
        isFolderUploading
      );
    }
    dispatch(setFilesToBeUploaded(fileList));
    handleCancelButton();
  }, [
    agency_id,
    dispatch,
    fileList,
    foldersList,
    handleCancelButton,
    uploadType,
  ]);

  const handleDeleteUploadedData = useCallback((index) => {
    setFileList((prevFileList) => {
      const updatedFileList = [...prevFileList];
      updatedFileList.splice(index, 1);
      return updatedFileList;
    });
  }, []);

  return (
    <>
      <Modal
        centered={true}
        visible={isModalOpen}
        footer={null}
        className="upload-modal"
        onCancel={handleCancelButton}
      >
        <h2 className="modal-title">Upload {uploadType}</h2>
        <div
          className="upload-wrapper"
          style={{ position: !fileList.length ? "relative" : "inherit" }}
        >
          <div style={{ marginTop: "3rem" }}>
            {!fileList.length ? (
              <img src={uploadIcon} alt="Upload Icon" />
            ) : (
              <></>
            )}

            {!!fileList.length && (
              <div className="uploaded-files-wrapper">
                {fileList.map((file, fileIndex) => {
                  if (uploadType === "file") {
                    const extension: string =
                      file.name
                        .split(".")
                        .pop()
                        ?.toLowerCase() || "";
                    const hasExtension: boolean =
                      file.name.split(".").length > 1;
                    const url: string =
                      file.type.includes("image/") && extension !== "psd"
                        ? file.thumbUrl
                        : detectProjectType({ ...file, file_type: extension });

                    return (
                      <div key={fileIndex} className="uploaded-file">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <div
                            className="deleteFileIcon"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents triggering the file input
                              handleDeleteUploadedData(fileIndex);
                            }}
                          >
                            <img src={removeIcon} alt="Remove Icon" />
                          </div>
                          <div className="fileTypeIconWrapper">
                            <img
                              src={detectFileIconType({
                                ...file,
                                file_url: url,
                                file_type: extension,
                              })}
                              alt="File Icon"
                            />
                          </div>
                        </div>
                        <div className="file_wrapper">
                          {url && (
                            <img
                              className=" file_image_local"
                              src={url}
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src = RandomPreview;
                              }}
                              alt="fileImage"
                            />
                          )}
                        </div>
                        <div
                          className="file_content_wrapper"
                          style={{ paddingTop: "2rem !important" }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingTop: "2rem !important",
                            }}
                          >
                            <Tooltip placement="bottomRight" title={file?.name}>
                              <div className="image_title">{file?.name}</div>
                            </Tooltip>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div
                          className="folders-content"
                          style={{ marginRight: "2rem" }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <div
                              className="deleteFolderIcon"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents triggering the file input
                                handleDeleteUploadedData(fileIndex);
                              }}
                            >
                              <img src={removeIcon} alt="Remove Icon" />
                            </div>
                          </div>
                          <div className="folder-data" key={file.id}>
                            <div className="folder_info_wrapper">
                              <img src={folderIcon} alt="Folder Icon" />
                              <div className="folder_info">
                                <Tooltip
                                  placement="bottomRight"
                                  title={file.name}
                                  key={file.id + file.name}
                                >
                                  <div className="folder_name">
                                    {file.name.length > 45
                                      ? `${file.name.slice(0, 45)}...`
                                      : file.name}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            )}
            <div
              style={{ position: !!fileList.length ? "relative" : "inherit" }}
            >
              <div className="dragAndDropTitle">
                Drag and drop your {uploadType}s{" "}
              </div>
              <div className="uploadTitle" >
                Upload from computer
              </div>
              {uploadType === "file" ? (
                <input
                  ref={fileUpload}
                  multiple
                  type="file"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                  onClick={(e: any) => (e.target.value = null)}
                  onChange={handleChange}
                />
              ) : (
                <input
                  ref={folderUpload}
                  multiple
                  type="file"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                  onClick={(e: any) => (e.target.value = null)}
                  onChange={handleFolderUpload}
                />
              )}
            </div>
          </div>
        </div>

        <div className="modal-buttons">
          <Button
            className="button create-button"
            type="primary"
            onClick={handleUploadFilesOrFolders}
            disable={!fileList.length}
          >
            Upload
          </Button>
          <Button className="button cancel-button" onClick={handleCancelButton}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UplaodFilesOrFolderModal;
